header{
  
  background: #fff;
  
  border: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%) 5%;
  
  // // position: fixed;
  // left:0;
  // right:0;
  // top: 0;
  // margin:auto;
  // max-width: $max-body-size;
  // z-index: 100;
  // box-shadow: 0 3px 5px 0.3px rgba(0,0,0,.1);
  
  // height:$header-height;
  
  // @include desktop {
  //   height: $header-height-desktop;
  // }
  
  // &.header-invert {
  //       box-shadow: none;
  //       background: transparent;
  //       position: fixed;
        
  //       .header-title {
  //           background-image: url('/assets/images/logo-loyall-white.png');
  //       }
        
  //       #header-phone-button {
  //           background:transparent;
  //           border-color: #fff;
  //           color:#fff;
  //       }
        
  //       .nav-items.desktop .nav-link {
  //           color: #fff;
  //       }
        
  //       .hamburger > .line {
  //           background: #fff;
  //       }
  //   }
  
  
  .nav-container {
    // height:100%;
    // max-width:97%;
    // width:$max-size;
    // margin:auto;
    // display: flex;
    // align-items: baseline;
    // justify-content: space-between;
    
    // @include desktop {
    //   max-width: 100%;
    // }
  }
  
  .header-title {
      padding: 18px 50px;
      background-image: url('/assets/images/logo-loyall.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center left;
      
      // @include desktop {
      //   padding: 15px 60px;
      // }
      
    }
  
  .right-nav {
    display: inline-flex;
    // height: 100%;
    // align-items: center;
  }
  
  .nav-link {
    // @include default-transition;
    font-size:14px;  
    text-transform: uppercase;
    color: $color-text;
    cursor:pointer;
    opacity: 0.7;
    white-space: nowrap;
    // padding-right:30px;
    font-weight:600;
    
    
    &:hover {
      opacity:1;
    }
    
    &.has-children {
      // padding: 30px 40px 30px 10px;
      
       &:after {
         position: absolute;
         top: 50%;
         width: 6px;
         height: 6px;
         background: transparent;
         border-top: 2px solid;
         border-right: 2px solid;
         content: ' ';
         transform: translate3d(0, -50%, 0) rotate(135deg);
         margin-left: 9px;
       }
    }
  }
  
  .menu-dropdown {
    position:relative;
    display: inline-block;
    &:hover {
      >.list-container{
        // display:block;
        // opacity:1;
        webkit-transition: opacity .17s ease-out,-webkit-transform .17s ease-out;
        transition: opacity .17s ease-out,-webkit-transform .17s ease-out;
        transition: opacity .17s ease-out,transform .17s ease-out;
        transition: opacity .17s ease-out,transform .17s ease-out,-webkit-transform .17s ease-out;
        opacity: 1;
        // -webkit-transform: translate(-50%,0);
        // transform: translate(-50%,0);
        visibility: visible;
      }
    }
  }
  
  .list-container {
    display: block;
    background: #fff;
    border-radius: 2px;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    z-index: 1;
    -webkit-box-shadow: 0 10px 60px -4px rgba(27,40,54,.7);
    box-shadow: 0 10px 60px -4px rgba(27,40,54,.7);
    opacity: 0;
    -webkit-transform: translate(-50%,6px) scale(.94);
    transform: translate(-50%,6px) scale(.94);
    visibility: hidden;
    
    &:before {
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      left: 50%;
      top: -5px;
      height: 10px;
      width: 10px;
      padding: 0;
      background: #fff;
      border-top-left-radius: 3px;
      -webkit-transform: translateX(-12px) rotateZ(45deg);
      transform: translateX(-12px) rotateZ(45deg);
    }
  
    a {
      color: $color-text;
      cursor:pointer;
      display: block;
      width:100%;
      font-size: 0.8em;
      padding:10px;
      white-space: nowrap;
      
      &:hover{
        background-color:$color-grey-light;
      }
    }
  }
  
  #toggle {
    appearance: none;
    visibility:hidden;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    padding: 10px;
  }
  
  .hamburger .line {
    width: 30px;
    height: 2px;
    background-color: $color-text;
    display: block;
    margin: 5px auto;
    margin-right:15px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  
  
  #toggle:checked ~ #drawer {
    opacity: 1;
    visibility: visible;
  }
  
  #toggle:checked ~ label>.line:nth-child(2) {
    opacity: 0;
  }
  
  #toggle:checked ~ label>.line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(7px) rotate(45deg);
  }
  
  #toggle:checked ~ label>.line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-7px) rotate(-45deg);
  }

  #header-phone-button {
    margin-right:20px;
  }
  
  
  #drawer {
    @include default-transition;
    visibility:hidden;
    
    ul > li {
      padding: 15px 10px;
      margin: 0 15px;
      &:not(:first-child){
        border-top: 1px solid #dad6d6;
      }
      &:first-child{
        margin-top:17px;
      }
    }
    
    .nav-link {
      width: 100%;
      display:block;
      font-size: 18px;
    }
    
    ul.submenu {
      display:none;
      @include default-transition;
      
       >li {
        border: none;
        padding-left:0;
        margin-left:0;
        a {
          text-transform:none;
        }
      }
    }
    
    .submenu-label {
      position:relative;
      display:block;
      padding:0;
       &:after {
         position: absolute;
         top: calc(50% - 0.3em);
         width: 10px;
         height: 10px;
         right:0;
         background: transparent;
         border-top: 1px solid;
         border-right: 1px solid;
         content: ' ';
         transform: rotate(135deg);
         margin-left: 9px;
         @include default-transition;
       }
    }
    
    .submenu-toggle {
      display: none;
    }
    .submenu-toggle:checked ~.submenu {
      // height:auto;
      display:block;
      // transform: scaleY(1);
    }
    .submenu-toggle:checked ~.submenu-label:after {
      // transform: scale(-1, 1);
      transform: rotate(315deg);
    }
    
    
    ul>li.button-item {
      padding-top:20px;
      border-top:0;
    }
  }
  .lang-selector {
    padding-left: 17px !important;
    &:before {
      left: -5px;
      content: "language";
      font-size: 17px !important;
      margin-right: 10px;
      top: 3px;
      position: absolute;
      @extend .material-icons;
    }
  }
}
