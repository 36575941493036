.pricing-plan {

  >.title-block,
  >.features-block {
    padding: 10px;
    min-height: 280px; // >h3 {
    //   color:$color-primary;
    //   font-size: 1em;
    // }
    .pricing {
      font-weight: 600;
      padding: 15px 0;
    }

    >p,
    a,
    li {
      font-size: 0.9em;
    }
  }
}

.card {
  display: block;
  // height:100%;
  // width: 100%;
  // padding: 1.25rem 1rem;
  position: relative;
  background: #fff;
  will-change: transform;
  
  border-radius: .1875rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.06);
  will-change: transform;
  transition: transform .2s ease-in;
  
  &:before {
    
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  }
  
  &:after {
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
    opacity: 0;
  }
  
  
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: .1875rem;
    will-change: opacity;
    transition: opacity .2s ease-in;
  }
  
  &:hover {
    transform: translateY(-1px);
    color:initial;
    
    &:after {
      opacity: 1;
    }
  }
  
  .card-title {
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;
    font-size: 1.09375rem;
    color:#000;
  }
  
  .fab {
    box-shadow: 0px 2px 2px 0 rgba(0,0,0,0.14);
  }
  

  .product_card_image {
    background: center / contain no-repeat transparent;
    // -ms-flex: 1;
    flex: 1;
    height: 165px;
    margin: 30px auto 15px;
    transition: transform .3s cubic-bezier(0.25, 0.1, 0.25, 1),background .3s;
    width: 80%;
    will-change: transform;
    
  }
  
}

