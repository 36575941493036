/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/source-sans-pro-v10-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('/assets/fonts/source-sans-pro-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/source-sans-pro-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/source-sans-pro-v10-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/source-sans-pro-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('/assets/fonts/source-sans-pro-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/source-sans-pro-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/source-sans-pro-v10-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/source-sans-pro-v10-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('/assets/fonts/source-sans-pro-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/source-sans-pro-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/source-sans-pro-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/source-sans-pro-v10-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot');
  src:  url('/assets/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf') format('truetype'),
    url('/assets/fonts/icomoon.woff') format('woff'),
    url('/assets/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}





// @font-face {
//   font-family: 'Open Sans';
//   font-weight: 300;
//   font-style: normal;
//   src: url('/assets/fonts/Open-Sans-300/Open-Sans-300.eot');
//   src: url('/assets/fonts/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
//       local('Open Sans Light'),
//       local('Open-Sans-300'),
//       url('/assets/fonts/Open-Sans-300/Open-Sans-300.woff2') format('woff2'),
//       url('/assets/fonts/Open-Sans-300/Open-Sans-300.woff') format('woff'),
//       url('/assets/fonts/Open-Sans-300/Open-Sans-300.ttf') format('truetype'),
//       url('/assets/fonts/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-weight: 400;
//   font-style: normal;
//   src: url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.eot');
//   src: url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
//       local('Open Sans'),
//       local('Open-Sans-regular'),
//       url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
//       url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
//       url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
//       url('/assets/fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-weight: 600;
//   font-style: normal;
//   src: url('/assets/fonts/Open-Sans-600/Open-Sans-600.eot');
//   src: url('/assets/fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
//       local('Open Sans Semibold'),
//       local('Open-Sans-600'),
//       url('/assets/fonts/Open-Sans-600/Open-Sans-600.woff2') format('woff2'),
//       url('/assets/fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'),
//       url('/assets/fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'),
//       url('/assets/fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
// }