.bg-images {
    
    width: 100%;

    // &:before {
    //     content: " ";
    //     display: table;
    // }
    
 
 
&.darken {
    position:relative;
    
    p, h1, h2, h3 {
  position: relative;
  z-index: 1;
        
    }
}
&.darken:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
    
    
    &.segment-company {
        @include background-image('segment-company-min.jpg');
    }
    &.segment-education {
        @include background-image('segment-education-min.jpg');
    }
    &.segment-ngo {
        @include background-image('segment-ngo-min.jpg');
    }
    &.case-vinet {
        @include background-image("case-vinet-small-min.jpg");
    }
    &.case-plutschow {
        @include background-image("clients/plutschow-gallery-min.jpg");
    }
    &.case-dmt {
        @include background-image("clients/plutschow-gallery-min.jpg");
        background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?dpr=1&auto=format&fit=crop&w=1500&h=1001&q=80&cs=tinysrgb&crop=');
    }
    &.hero-whyloyall {
        // @include background-image("Design.png");
        // background-position: bottom center;
        // background-color:rgba(0, 0, 0, 0.5);
        &:before {
          content: " ";
          position: absolute;
          top: 0; 
          left: 0;
          width: 100%; 
          height: 100%;  
          opacity: 0.1; 
        //   z-index: -1;
        //   background-color:rgba(255, 255, 255, 0.5);
          background: url(/assets/images/whyloyall-hero-min.jpeg);
          background-position: bottom center;
          background-size:100%;
          background-repeat: no-repeat;
        }
        
    }
    &.hero-gsuite {
        // @include background-image("gsuite-webstore-min.png");
        
        // background-size: 50%;
        // background-position: bottom;
        // @include desktop {
        //     background-size: 40%;
        //     background-position: 85% bottom;    
        // }
        
    }
    &.hero-chromebooks {
        $chromebook-image-url: 'http://www.log.com.tr/wp-content/uploads/2013/02/Google-Chromebook-Pixel6.jpg';
        // background-image: linear-gradient(-180deg,rgba(255,255,255,.6) 0,rgba(255,255,255,0.6) 100%), url($chromebook-image-url);
        background-image: url($chromebook-image-url);
        background-repeat: no-repeat;
        background-position:right bottom;
        background-size: 90%;
        @include desktop{
            background-position: right 100px;
            background-size: 50%;  
        }
        @include tablet{
            background-position: right 100px;
            background-size: 50%;  
        }
        
    }
        
}