
html, body {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: $color-text;
  font-family: $font-family;
  margin: auto;
  overflow-x: hidden;
  padding: 0;
  max-width:$max-body-size;
  text-rendering: optimizeLegibility;
  
  /**
    * 1. Making the <body> element behave like a flexbox container.
    * 2. Making flex children line up vertically (horizontal is default)
    * 3. Setting the min-height to 100% of the viewport height.
    */

    /* [1] */
    /* Ye Olde Flexbox for Webkit */
    display: -webkit-box;
    /* Tweener flexbox for IE10 */
    display: -ms-flexbox;
    /* Prefixed "new flexbox" */
    display: -webkit-flex;
    /* unprefixed standard "new flexbox" version for the rest */
    display: flex;

    /* [2] */
    /* Your grandparents flexbox for old Webkit */
    -webkit-box-orient: vertical;
    /* Prefixed new syntax: */
    -webkit-flex-direction: column;
    /* (Same syntax for weird IE tweener) */
    -ms-flex-direction: column;
    /* unprefixed new syntax */
    flex-direction: column;

    /* [3] */
    min-height: 100vh;
    
    font-size:$mobile-font-size;
    line-height: $mobile-line-height;
    font-weight:400;
    line-height: 1.625;
    
    @include desktop {
      font-size:$desktop-font-size;
      line-height: $desktop-line-height;
    }
}


@media (min-width: $max-body-size) {
  html {
    background-color: #EEEEEE;
  }
  body {
    box-shadow: 0px 2px 20px 3px rgba(0,0,0,0.05);
  }
}

// h1, h2, h3, h4, h5, p {
//   font-family: $font-family;
//   // margin-top:2rem;
// }

h1 {
  // max-width: 50rem;
  //   font-weight: 400;
  //   font-size: 36px;
  //   line-height: 42px;
  //   position: relative;
  //   text-align: center;
  //   @include desktop {
  //       font-size: 48px;
  //       line-height: 55px;
  //   }
  // font-size: 42px;
  // line-height: 3.1rem;
  // margin-bottom: 3rem;
  // font-weight: 100;
}


// Tachyons Extended

.u {
  
  padding-bottom: 0.4rem;
  margin-bottom:1.2rem;
  &:after {
          left: 1px;
          z-index: 100;
          width: 3rem;
          height: 2px;
          content: " ";
          bottom: -8px;
          position: absolute;
          background: $accent-color;
        }
}  
  
  
// .carousel-preview button {
//     height: 60px;
//     width: 80px;
//     min-width: 60px;
//     margin: 4px;
//     padding: 0;
// }
  
#carousel-with-preview amp-img {
      transition: opacity 1s ease-in-out;
    }
  
  // @for $i from 1 through 8 {
    
  //   $width: $i * $i;
  //   .u#{$i} {
  //       &:after {
  //         left: 1px;
  //         z-index: 100;
  //         width: #{$i}rem;
  //         height: 2px;
  //         content: " ";
  //         bottom: -8px;
  //         position: absolute;
  //         background: var(--accent-color);
  //       }
  //   }
  // }




h2 {
  font-size: 28px;
  line-height: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 300;
  // letter-spacing: -0.5px;
  font-weight: 500;
  margin-bottom: 0.5em;
  position:relative;
  
  // &:after {
  //   left: 1px;
  //   z-index: 100;
  //   width: 30px;
  //   height: 2px;
  //   content: " ";
  //   bottom: -5px;
  //   position: absolute;
  //   background: #8ba93f;
  // }
}


p {
  // line-height: 1.58rem;
  
  margin-top: 1rem;
  &.text-block {
    font-weight: 300;
    line-height: 22px;
  }
}

.anchor::before {
  display: block;
  content: " ";
  margin-top: -85px;
  height: 85px;
  visibility: hidden;
}

/**
* Reset some basic elements
*/

body, blockquote, pre, hr, dl, dd, ul, ol, figure {
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    list-style-type: none;
    font-weight: inherit;
}

*, *:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    // position:relative;
  }

%clearfix {
  &:before, 
  &:after {
      content: " ";
      display: table;
  }
  &:after {
      clear: both;
  }
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  // min-height: 100vh; /* These two lines are counted as one :-)       */
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.flexbox {
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    justify-content: space-between;
    @extend %clearfix;
    
    &.justify {
      justify-content: space-around;
    }
  
}

ul.disk {
  -webkit-margin-before: 1em;
  padding: 1em;
  list-style-type: disc;
}

a {
  color: $color-link;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  
  @include default-transition;
  
  &:hover {
    color:$color-link-hover;
  }
  
  
  &.blank:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAA1VBMVEUAAAAAAAAAAAAAAAAAAAAAAABEREBEREAWFhUCAgEAAAAAAAAAAAAAAAAGBgVEREBEREAGBgUAAAAAAAAAAAAEBAMEBAQJCQhEREAAAAABAQFEREBEREAAAAABAQFEREAAAAAAAAABAQEFBQQAAAACAgEnJyUDAwMAAAAGBgYAAAAAAAAAAAACAgE3NzMICAgAAAAAAAACAgE1NTIAAAAAAAADAwMUFBMAAAAAAAAAAAAAAABEREAAAAAAAAAAAAAEBAQAAAAAAAAAAAAAAAACAgIDAwMdZvJPAAAAQXRSTlMAO/20AQQBBBDC/Fju/mcKCQ3B/Pz8+mcCb/wND2j8AWn5V8RqSxvD9QwsQ/hKE25u9kkRZl8pBvsI7eQFUOfkS+2C9b0AAADDSURBVBhXPY5ZI8NAGEWvMg2f0hDLmBmJ2tdagliKHm36/3+Sh+K+nfNyrvS7hQ4Ai9KSc851s+WVbwDTag9YW+/nG5sAhXqYbW3v7HrtAZMgTD6m6LUPTNtSWBVT9DqAweHRLIgiT9HrGAYn8fTsXIT+P6d4cSnK3OsKrm/i8NZLIlTSHfcPMdXTR0kUktzTc0yx4UUSJmledtifmD9xmLqi41xTD+vGuVesyvQGk3bWTgB4z0f6+CxCGQozM/saj7IfdYwgMMozvAsAAAAASUVORK5CYII=");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    content: ' ';
    display: inline-block;
    width:1.2em;
    height:1em;
    position:relative;
    // left: 5px;
    
  }
  
  &.learn_more {
    white-space: nowrap;
    
    &:after {
      content: '\02192';
      font-size:1em;
    }
  }
}

.circle-image{
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -khtml-border-radius: 50%;
    border: 3px solid #fff;
    text-align:center;
    
    img {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      -khtml-border-radius: 50%;
      vertical-align: middle;
    }
}



amp-img.shadow {
  @include box-shadow;
}
// i {
//   width:100%;
// }

.arrow {
  
  &--left {
    
    &:before {
      content: '\2190';
      font-size: 1.5em;
    }
  }
  
}

// .arrow {
  
//   &:after {
//     position: absolute;
// 	top: 50%;
// 	width: 2rem;
// 	height: 2rem;
// 	background: transparent;
// 	border-top: .4rem solid black;
// 	border-right: .4rem solid black;
// 	box-shadow: 0 0 0 lightgray;
// 	transition: all 200ms ease;
  
	
	
// 	&.left {
// 		left: 0;
// 		transform: translate3d(0,-50%,0) rotate(-135deg);
// 	}

// 	&.right {
// 		right: 0;
// 		transform: translate3d(0,-50%,0) rotate(45deg);
// 	}
//   }
// }

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.mobile-centered {
  @include mobile {
    text-align:center;
  }
}

.right-align {
  text-align: right;
  
}

.feature {
  @extend %clearfix;
  // clear: both;
  position:relative;
  
  

  @include desktop {
    padding-left: 500px;
  }
  
  &.feature-right {
    @include desktop {
      padding-right: 500px;
      padding-left: 0;
    }
    
    >img.background, >amp-img.background {
      @include desktop {
        right: 180px;
        left:auto;
        
      }
    }
  }
  
  & h2 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom:0;
    padding-bottom:0;
  }
  
  .background {
    
    position: static;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.13rem;
    max-width: 370px;
    
    @include desktop {
      max-width: 370px;
      position: absolute;
      height: auto;
      vertical-align: middle;
      margin: 0;
      border: 0;
      left: 1.5rem;
    }
    
  }
  
  & h1, & p {
    max-width: 550px;
  }
  
}

div.flex {
  display:inline-flex;
}

hr {
  display:block;
  max-width: $max-size;
  // margin: 0 15%;
  // padding: 0 17px;
  border:0;
  height:1px;
  // text-align:center;
  background:$color-grey-light;
}


.desktop {
  @include mobile {
    display:none;
  }
  @include tablet {
    display:none;
  }
}

.mobile {
  @include desktop {
    display:none;
  }
}
  
  .profile-photo {
    max-width:150px;
    max-height:150px;
  }
  .blockquote {
    max-width: 90%;
    >p {
      margin-left:20px;
    }
  
    .quote {
      font-family: Georgia, serif;
      position: relative;
      font-style: oblique;
  
      &::before {
        content: "\201C";
        font-size: 60px;
        color: #999;
        position: absolute;
        left: -30px;
      }
    }
  }
  
  
////////////////////////////////////////////////////////////////////////////////


.nobr {white-space: nowrap };


em {
    font-style: normal;
    font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.small {
  font-size: 0.8em;
}



.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}

.short {
    max-width: 80%;
    margin:auto;
    
    // @include max-screen($mobile-breakpoint) {
    //   max-width: 100%;
    // }
  }

.full-width {
  width:100%;
}

// .inline {
//   display: inline-flex;
// }


// .contact-btn {
//         color: #fff;
//         border-color: #27ae60;
//         background:#27ae60;
//         border-radius: 3px;
//         max-height: 38px;
//         padding: 0.3em 1em;
    
//     > a{
//         color:#fff;
//         opacity:1;
//         text-transform: uppercase;
//         font-weight:500;
//     }
//   }

// @include max-screen($mobile-breakpoint) {
//   .margin-bottom-mobile:last-child,
//   .margin-bottom-mobile {
//     margin-bottom: 40px;
//   }
// }

// .margin-bottom-sm:last-child,
// .margin-bottom-sm {
//   margin-bottom: 40px;
// }

// .margin-bottom:last-child,
// .margin-bottom {
//   margin-bottom: 60px;

//   &.desktop {
//     @include max-screen($mobile-breakpoint) {
//       margin-bottom: inherit;
//     }
//   }
// }

.hidden {
  display: none;
}




.btn {
  padding: 9px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.78;
  display: inline-block;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  // text-transform: uppercase;
  cursor: pointer;
  outline: 0;
  border: 2px solid;
  letter-spacing: .3px;text-align: center;
  
  
  &--small {
    padding: 3px 11px;
    font-size: 14px;
    border-radius: 3px;
    border-width: 1px;
  }
  
  &--fullwidth {
    width: 100%;
  }
  
  &--cta {
    background-color:$color-blue-dark;
    border-color:$color-blue-dark;
    color:#fff;
    
    &:hover {
      background-color:$color-blue-mid;
      border-color:$color-blue-mid;
      color:#fff;
    }
    
    &.green {
      background-color: $color-green;
      border-color: $color-green;
    }
    
  }
  
  &--round {
	  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
	  background-color:$color-blue-mid;
    border-radius: 50%;
    width:4em;
    height:4em;
    border:0;
    
  }
  
  &--light {
    background:#fff;
    border:0;
    color:$color-blue-dark;
    
    &:hover {
      background-color:#fff;
      color:$color-blue-mid;
    }
  }
  
  &--ghost {
    background:transparent;
    // border:0;
    color:$color-blue-dark;
    
    &:hover {
      background-color:transparent;
      color:$color-blue-mid;
    }
  }
  
}

.go-button {
  display: inline-block;
  background-color: $color-link-action;
  padding: 0.8em 3em;
  border: 1px solid $color-link-action;
  border-radius: 5px;
  color: #fff;
  // font-size: 14px;
  font-weight: 400;
  text-align: center;
  
  
  &:hover {
    color:#fff;
  }
  
  &.bright {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid $color-link-action;
    // border-radius: 5px;
    color: $color-primary;
  }
  
  &.transparent {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #fff;
    // border-radius: 5px;
    color: #fff;
  }
  
  &.alt {
    background-color: $color-white;
    border:0;
    color: $color-text;
    &:hover,
    &:focus {
      background-color: $color-grey-50;
    }
    
    &.flat{
      border-radius:0;
    }
    
  }
  
  &:disabled {
    background-color: $color-grey-200;
  }
  
  &.flat {
    box-shadow: 0 0 0 0;
  }
  
  // &.square {
  //   border-radius: 0px;
  // }
  
  &.uppercase {
     text-transform: uppercase;
  }
  
  &.thin {
    padding: 0.5em 1em;
  // border: 1px solid $color-link-action;
  border-radius: 2px;
  white-space: no-wrap;
  }
}

.shadow {
    -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	  -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  }



.panel-full {
  max-width:100vw;
  height:100vh;
  position:absolute;
  top:$header-height;
  right:0;
  left:0;
  z-index:999;
  background-color:#fff;
  opacity:0;
}

/////////////// Sprites


.sprites { 
  background: url(/assets/images/sprites-nq8.png) no-repeat; 
  display:inline-block;
  margin-right: 10px;
}
.facebook { width: 32px; height: 32px; background-position: -148px -49px; }
.google-plus { width: 32px; height: 32px; background-position: 0 -147px; }
// .ic_close_black_24dp_1x { width: 24px; height: 24px; background-position: -98px 0; }
// .ic_close_black_24dp_2x { width: 48px; height: 48px; background-position: 0 0; }
// .ic_close_white_24dp_1x { width: 24px; height: 24px; background-position: -123px 0; }
// .ic_close_white_24dp_2x { width: 48px; height: 48px; background-position: -49px 0; }
// .ic_done_black_24dp_1x { width: 24px; height: 24px; background-position: -98px -25px; }
// .ic_done_black_24dp_2x { width: 48px; height: 48px; background-position: -148px 0; }
// .ic_email_white_24dp_1x { width: 24px; height: 24px; background-position: -123px -25px; }
// .ic_email_white_24dp_2x { width: 48px; height: 48px; background-position: 0 -49px; }
// .ic_keyboard_arrow_right_black_24dp_1x { width: 24px; height: 24px; background-position: -98px -50px; }
// .ic_keyboard_arrow_right_black_24dp_2x { width: 48px; height: 48px; background-position: -49px -49px; }
// .ic_language_black_24dp_1x { width: 24px; height: 24px; background-position: -123px -50px; }
// .ic_language_black_24dp_2x { width: 48px; height: 48px; background-position: -123px -82px; }
// .ic_language_white_24dp_1x { width: 24px; height: 24px; background-position: -98px -75px; }
// .ic_language_white_24dp_2x { width: 48px; height: 48px; background-position: 0 -98px; }
// .ic_menu_black_24dp_1x { width: 24px; height: 24px; background-position: -172px -82px; }
// .ic_menu_black_24dp_2x { width: 48px; height: 48px; background-position: -49px -98px; }
// .ic_menu_white_24dp_1x { width: 24px; height: 24px; background-position: -98px -100px; }
// .ic_menu_white_24dp_2x { width: 48px; height: 48px; background-position: -99px -131px; }
// .ic_phone_white_24dp_1x { width: 24px; height: 24px; background-position: 0 -180px; }
// .ic_phone_white_24dp_2x { width: 48px; height: 48px; background-position: -148px -131px; }
.linkedin { width: 32px; height: 32px; background-position: -33px -147px; }
.loyall { width: 160px; height: 65px; background-position: -33px -180px; }
.twitter { width: 32px; height: 32px; background-position: -66px -147px; }
.xing { width: 32px; height: 32px; background-position: 0 -205px; }