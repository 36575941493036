.contact {

    .contact-form-section {
        
        
        // margin-top: -220px;
        // @include desktop {
        //     margin-top: -350px;
        // }
        
    }
    
    .hero-wave .wave {
        fill:#00bcd4;
    }
   
   .contact-card {
       background-color: #fff;
       margin-top: 70px;
       display: flex;
       flex-direction: column;
       align-items: stretch;
    //   font-size: 0.9em;
   
       .left,
       .right {
           padding: 20px 40px;
           width: 100%;
       }
   
       .right {
           background-color: #37474e;
           * {color: #fff};
       }
   
       @include desktop {
           // margin-top:-300px;
           flex-direction: row;
   
           .left {
               min-width: 65%;
           }
   
           .right {
               flex-grow: 1;
           }
       }
       
       .theme--block {
           
           background: transparent;
        //   color:#fff;
           
           .material-icons {
               margin-right:1em;
            //   font-size:2em;
               opacity:0.6;
           }
       }
       
       .social {
           display:flex;
           flex-direction:row;
           
       }
   }
}