// // img {
// //     width: auto;
// //     max-width: 100%;
// //     // height: auto !important; 
// //     }



// // .cols-row {
// //     align-content: flex-start;
// //     align-items: flex-start;
// //     display: flex;
// //     flex-direction: row;
// //     flex-wrap: wrap;
// //     justify-content: space-between;
// //     padding-top: 70px;
    
// //     > div, > p {
        
// //         @for $i from 1 through $grid-columns {      
// //             &.col-l-#{$i} {         
// //                 min-width: 90% / 12 * $i;
// //                 max-width: 90% / 12 * $i;
// //                 flex: 0 0 90% / 12 * $i;
// //             }           
// //         }
// //     }
    
// //     @for $i from 1 through $grid-columns {   
        
// //         &.cols-#{$i} {
            
// //             > * {
// //                 min-width: 90%;
// //                 max-width: 90%;
// //                 flex: 0 0 90%;
// //             }
            
// //             @include desktop {
// //                 > * {
// //                     min-width: 90% / $i;
// //                     max-width: 90% / $i;
// //                     flex: 0 0 90% / $i;
// //                 }
// //             }
// //             @include tablet {
// //                 > * {
// //                     min-width: 45%;
// //                     max-width: 45%;
// //                     flex: 0 0 45%;
// //                 }
// //             }
// //         }           
// //     }
    
// // }

// // .grid {
// //     display:grid;
// //     grid-column-gap: 50px;
// //     grid-row-gap: 50px;
// //     align-items: center;
// //     max-width:100%;
// //     grid-template-columns: repeat(1, 1fr);
// //     align-items:stretch;
    
// //     // MS SHIT
// //     // display: -ms-grid;
// //     // -ms-grid-columns: repeat(1, 1fr);
    
// //     @include desktop {
// //         grid-template-columns: repeat(12, 1fr);
        
// //         @for $i from 1 through $grid-columns {      
// //             .cell-#{$i} {         
// //                 grid-column: span $i;
// //                 // -ms-grid-columns: span $i;
// //                 -ms-grid-column-span: $i
// //             }           
// //         }
// //     }
    
// //     &--3-cols {
// //         @include desktop {
// //             grid-template-columns: repeat(3, 1fr);
// //             -ms-grid-columns: 1fr 1fr 1fr;
// //         }
// //     }
    
// //     &--2-cols {
// //         @include desktop {
// //             grid-template-columns: repeat(2, 1fr);
// //         }
// //     }
// // }


// .cols-row {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -ms-flex-line-pack: start;
//     align-content: flex-start;
//     -webkit-box-align: stretch;
//     -ms-flex-align: stretch;
//     align-items: stretch;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     max-width:100%;
//     padding-top:50px;
//     @extend %clearfix;
    
//     &.full-width {
//         @include mobile {
//           >.col {
//             padding-right:0;
//             }  
//         }
//     }
    
//     &.no-spacing {
//         padding-top:0;
//     }
    
//     &.left {
//         justify-content: flex-start;
//     }
    
//     &.middle {
//         align-items: center;
//     }
    
//     &.flow {
//         @include mobile {
//             flex-wrap: nowrap;
//             overflow-x: auto;
//             overflow-y: hidden;
//             max-width:100%;
//             margin-right: -17px
//         }
        
//         >.col {
//             @include mobile {
//                 max-width:85%;
//                 min-width:85%;
//                 padding-right:10px;   
//             }
//         }
//     }
    
//     &.flex {
//         > .col {
//             display:flex;
//             align-items: stretch;
//         }
        
//     }
// }

// .col {
//     ///
//     -webkit-flex: 1 1 100%;
//     -ms-flex: 1 1 100%;
//     max-width: 100%;
//     margin: 0;
//     min-width:100%;
//     padding-bottom: 30px;
    
//     @include desktop {
//         padding-right:20px;
//         padding-bottom: 10px;
//     }
    
//     &.bottom {
//         align-items: flex-end;
//     }
// }

// @include mobile {
//     @for $i from 1 through $grid-columns {      
//         .col-m-#{$i} {         
//             min-width: 99.5% / 4 * $i;
//             max-width: 99.5% / 4 * $i;
//             flex: 0 0 99.5% / 4 * $i;
//         }           
//     }
// }

// @include tablet {
//     @for $i from 1 through $grid-columns {      
//         .col-s-#{$i} {         
//             min-width: 99.5% / 8 * $i;
//             max-width: 99.5% / 8 * $i;
//             flex: 0 0 99.5% / 8 * $i;
//         }           
//     }
// }

// @include desktop {
//     @for $i from 1 through $grid-columns {      
//         .col-l-#{$i} {         
//             min-width: 99.5% / 12 * $i;
//             max-width: 99.5% / 12 * $i;
//             flex: 0 0 99.5% / 12 * $i;
//         }           
//     }
// }