.primary-color                      {color: $primary-color}
.bg-primary-color                   {background-color: $primary-color}
.hover-bg-primary-color:hover       {background-color: $primary-color}

.primary-color-dark                  {color: $primary-color-dark}
.bg-primary-color-dark               {background-color: $primary-color-dark}
.hover-bg-primary-color-dark:hover   {background-color: $primary-color-dark}

.primary-color-light                  {color: $primary-color-light}
.bg-primary-color-light               {background-color: $primary-color-light}
.hover-bg-primary-color-light:hover   {background-color: $primary-color-light}

.accent-color                         {color: $accent-color}
.bg-accent-color                      {background-color: $accent-color}
.hover-bg-accent-color:hover          {background-color: $accent-color}

.accent-color-light                   {color: $accent-color-light}
.hover-accent-color-light:hover       {color: $accent-color-light}
.bg-accent-color-light                {background-color: $accent-color-light}
.hover-bg-accent-color-light:hover    {background-color: $accent-color-light}

.accent-color-dark                    {color: $accent-color-dark}
.bg-accent-color-dark                 {background-color: $accent-color-dark}
.hover-bg-accent-color-dark:hover     {background-color: $accent-color-dark}

.text-color-primary    {color: #373737}
.text-on-primary-color {color: #fff}
.text-color-secondary  {color: #ccc}
.text-color-disabled   {color: #F7f7f7}
.divider-color         {color: #f6f6f6}
.warn-color            {color: red}


.nested-links a {
  color: #0594CB;
  text-decoration: none;

}