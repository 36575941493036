.case-studies {
    
    .card--image{
        
        // min-height:200px;
        
        &.vinet {
            @include background-image("case-vinet-small-min.jpg");
            background-position: right;
        }
        
        &.plutschow {
            @include background-image("clients/plutschow-gallery-min.jpg");
        }
        
        &.dmt {
            @include background-image("clients/plutschow-gallery-min.jpg");
            background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?dpr=1&auto=format&fit=crop&w=1500&h=1001&q=80&cs=tinysrgb&crop=');
        }
    }
}

// .case {
    
//     .top {
//         background-repeat: no-repeat;
//         background-size: cover;
//         * { color: #fff;}
        
//         @include background-overlay("dark", 0.65);
//     }
    
//     .vinet {
//       .top {
//           background-image: url('/assets/images/case-vinet-min.jpg');
//           background-position: 50%;
//       } 
//     }
//     .plutschow{
//         .top {
//           background-image: url('/assets/images/clients/plutschow-gallery-min.jpg');
//           background-position: bottom;
//         }
//     }
//     .dmt{
//         .top {
//             @include background-overlay("dark", 0.65);
//             background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?dpr=1&auto=format&fit=crop&w=1500&h=1001&q=80&cs=tinysrgb&crop=');
            
//             @include desktop {
//                 background-position: 0px -200px;
//             }
//         }
//     }
    
// }
