.terms {
    .container {
        max-width:800px;
        // @include max-screen($mobile-breakpoint) {
        //     max-width:96%;
        // }
    }
    
    & h1:not(.page-title), h2, h3 {
        text-align: left;
        // color: $color-blue;
        // font-family: 'Roboto Condensed';
        padding-top: 40px;
    }
    
}