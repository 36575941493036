.solutions {
    
    
    .hero-wave .wave {
        fill: #1dc720;
    }
    
    amp-youtube {
        @include box-shadow;
    }
    
    // .top.section-max{
    //     position:relative;
    // }
    // .top {
    //         background-color: rgba(245,245,245,0.6);
    //     }
        
    .hero--image {
        height: 300px;
        position: relative;
        // top: 0;
        width: 100%;
        
        .image {
            background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width:100%;
                height:100%;
        }
    }
        
        
    @include desktop {
    
        .hero--image{
            height: 100%;
            right: 0;
            margin-left: 0;
            margin-right: 0;
            max-height: none;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 50%;
        }
    }
    
    
    &.chromebooks {
        
       .hero-wave .wave {
            fill: #607d8b;
        } 
        
        .image {
            background-image: url('/assets/images/acer-chromebook-min.jpg');
        }
        
        .chromebook-features-section{
            
            $chromebook-image-url: 'http://www.log.com.tr/wp-content/uploads/2013/02/Google-Chromebook-Pixel6.jpg';
            
            background-image: linear-gradient(-180deg,rgba(255,255,255,.6) 0,rgba(255,255,255,0.6) 100%), url($chromebook-image-url);
            background-repeat: no-repeat;
            background-position:right -70px;
            background-size: 200%;
            
            
            @include desktop{
                background-image: url($chromebook-image-url);
                background-position: right bottom;
                background-size: 50%;  
            }
            
            
            @include mobile {
                h2 {
                    padding-bottom:70px;
                }   
            }
        }
        
        .mfg-logos {
            margin-top:-50px;
            margin-bottom: 50px;
            
            @include background-image('mfg_logos.png');
            background-size: 95%;
            @include desktop {
                background-size: 60%;    
            }
            
        }
        .devices-section {
            // padding: 30px 0;
        }
        .chromebook-card {
            @include box-shadow;
            
            @include desktop{
                // width:330px;
                
            }
            // padding: 20px 0;
            
            .card--image {
                position: relative;
                width: 100%;
                max-height: 150px;
                min-height:150px;
                padding:0;
                margin:auto;
                margin-bottom: 30px;
                
                img {
                    // max-width:100%;
                    max-height:160px;
                }
                
               > amp-img img, img {
                    object-fit: scale-down;
                  }
            }
            .card--title, .card--content {
                white-space: normal;
                margin-bottom:0;
            }
            
            .card--title {
                padding-top:0;
                font-size: 1em;
            }
            
            .card--content {
                // min-height: 200px;
            }
        }
    }
    
    .amp-carousel-slide {
        // border: 5px solid black;
        margin: 20px;

        background-color:#fff;
        // height:100%;
        overflow:auto;
        width:340px;
        display: block;
        min-height: 340px;
        // height:450px;
    }
    
    &.gsuite{
        
        // .image{
        //   background-image: url('/assets/images/gsuite-hero-min.jpg'); 
        // }
        
        .pricing-grid {
            .col {
                @include desktop {
                    padding-right: 5px;
                }
            }
        }
        
        .gsuite-webstore-image {
            
            bottom:0;
            max-width:90%;
            margin:auto;
            @include box-shadow;
            
            @include desktop {
                max-width:800px;
            }
        }
        .cert-box {
            max-width: 300px;
            padding:50px;
            margin: 0 10%;
        }
    }
    
    &.cloud {
        
        .hero-wave .wave {
            fill: #2196f3;
        }
        
        .image {
            background-image: url('/assets/images/server-room-min.jpg');
        }
    }
}