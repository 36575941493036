@import '_config.scss';

@import '_variables.scss';
@import '_color-schemes.scss';
@import '_tachyons.min.scss';

// @import '_header.scss';



@import '_mixins.scss';
@import '_global.scss';
@import '_fonts.scss';

@import '_header.scss';
@import '_forms.scss';

@import '_cards.scss';
@import '_grid.scss';
@import '_lists.scss';
@import '_md_icons.scss';
@import '_section.scss';
@import '_svg_icons.scss';
@import '_themes.scss';
@import '_picon.scss';
@import '_bg-images.scss';

@import 'pages/_case.scss';
@import 'pages/_article.scss';
@import 'pages/_terms.scss';
@import 'pages/_contact.scss';
@import 'pages/_home.scss';
@import 'pages/_lab.scss';
@import 'pages/_solutions.scss';
@import 'pages/_why-loyall.scss';
// @import 'pages/_campaign.scss';