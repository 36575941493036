.pricing-list {
  
  .plan-item {
    border-bottom: 1px solid #f1f3f5;
    opacity:0.7;
  }
}


ul{

  &.horizontal-list {
    li{
      display:inline-block;
    }
  }


  &.check-list li{
    position:relative;
    padding-left: 30px;
    
    
    &:before{
        /*Make it a small rectangle so the border will create an L-shape*/
      width: 0.35em;
      height: 0.7em;
      border: 1px solid black;
   
      /*Add a white border on the bottom and left, creating that 'L' */
      border: solid $color-text;
      border-width: 0 2px 2px 0;
   
      /*Rotate the L 45 degrees to turn it into a checkmark*/
      transform: rotate(45deg);
      
      content: ' ';
      position: absolute;
      display: block;
      top:0.35em;
      left: 0.35em;
    }
  }
  
  &.list--count {
    padding-left: 40px;
    position:relative;
    
    li {
      
      counter-increment: items;
      
      &:before {
          content: counter(items);
          position:absolute;
          border-radius: 50%;
          color:$color-blue-mid;
          background-color:#fff;
          width: 25px;
          height: 25px;
          left:0;
          text-align: center;
      }
    }
  }
  
}