.lab {
    
    // .hero-wave .wave {
    //     fill: #607d8b;
    // }
    
    // .headline .article-card{
    //     h3 >*{
            
    //         @include desktop {
    //             font-size: 1.8em;
    //             line-height:1.1em;
    //         }
            
    //     }
    // }
}