// .full {
//   width:100%;
//   padding:0;
//   margin:0;
//   position:relative;
// }

.top, 
.hero {
  
  // background: linear-gradient(180deg,#0d3d54 10%,#0a2d3f 70%);
  // margin-top:-($header-height);
  // * { color: #fff; }
  
  
  // h1 {
  //   max-width: 50rem;
  //   font-weight: 400;
  //   font-size: 36px;
  //   line-height: 42px;
  //   margin-bottom:3.5rem;
    
  //   @include desktop {
  //       font-size: 48px;
  //       line-height: 55px;
  //   }
  // }
  // p {
  //   font-weight: 400;
  //   max-width: 38rem;
  //   font-size: 20px;
  //   line-height: 30px;
  //   text-align: center;
  //   margin-bottom:3.5rem;
    
  //   @include desktop {
  //       font-size: 20px;
  //       line-height: 30px;
  //       margin-top: 3em;
  //   }
  // }
  
  
  // @include desktop {
  //   margin-top:-($header-height-desktop);
  // }
  
  &.thin {
     .section-max{
      
      &:before {
        
        height:100px;
        width:100%;
        
        @include desktop {
          height: 150px;
        }
      }
    }
    
    .hero-wave {
      max-height:100px;
    }
  }
  
  
  .section-max{
  
    padding-top:0;
    
    &:before {
      content: '';
      display: table;
      height:166px;
      width:100%;
      
      @include desktop {
        height: 250px;
      }
    }
  }
  
  .hero-wave {
      background:#fff;
      height:60px;
      position:relative;
      
      
      @include desktop {
          height: 200px;
      }
      
      svg {
        position: absolute;
      }

      .wave {
      	fill: $color-green;
      	mix-blend-mode: multiply;
      	opacity: 0.5;
      	z-index:10;
      }

      #gradient {
      	fill: #0a2d3f;
      	z-index:100;
      	opacity:1;
      // 	mix-blend-mode: multiply;
      }
  }
  
  
}

.section-max {
    margin: 0 auto;
    width: $max-size;
    max-width:98%;
    padding: 30px 17px;
    position:relative;
  
    @include desktop {
      padding: 70px 0;
    }
    
    &.flex {
      display: flex;
    }
    
    &.no-spacing {
      padding-top:0;
      padding-bottom:0;
    }
    
    &.offset-top {
      margin-top: -80px;
      padding-top:0;
    }
}

.section-short {
  @extend .section-max;
  
  @include desktop {
    > * {
      max-width: 66%; 
      margin: auto;
    }
  }
}

.section-half {
  @extend .section-max;
  
  @include desktop {
    > * {
      max-width: 50%;  
    }
  }
}


.grey-section { background-color:$color-grey-section; }

.alt-section {
  // background-image:linear-gradient(120deg,#1ecff3,#0da1e3);
}

.blue-section{
  
  @include background-overlay("blue");
  
  > * { color:#fff; }
}

.alt-2-section {
  
}

 .invert-text-position {
        @include desktop {
            order:-1;    
        }
        
    }

.section-title-block, .short {
    max-width: 50rem;
    
}
  
//   max-width: 700px;
  
//   p {
//     // max-width:90%;
//     margin: auto;
//   }
  
//   // &.centered {
//   //   max-width: 700px;
//   //   text-align:center;
//   //   margin:auto;
//   //   padding-right:0;
//   // }

  
//   // > h2, > h1{
//   //   font-size: 28px;
//   //   line-height: 44px;
//   // }
  
//   // > h3 {
//   //   font-size: 1.3em;
//   //   line-height: 1.3em;
//   // }
  
//   // > p{
//   //   font-size: 16px;
//   //   font-weight: 300;
//   //   line-height: 28px;
//   // }
// }

.page{
          
    // &-title {
    //     @extend .section-title-block;
    //     margin-bottom: 90px;
    //     // font-weight: 600;
    //     font-size: 45px;
    //     line-height: 3rem;
    //     position:relative;
        
    //     @include desktop {
    //       margin-bottom:50px;
    //       font-size: 50px;
    //       line-height: 3.7rem;
    //     }
        
    // }
    // &-subtitle {
    //     @extend .section-title-block;
    //     font-size: 25px;
    //     line-height: 1.3em;
    //     margin-bottom: 50px;
    // }
}

.stripe{
    background: #E91E63;
    bottom: 50px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50px;
    // min-width: 100vw;
    z-index: -1;
}

@-webkit-keyframes fadeInUp {0% {opacity: 0;-webkit-transform: translateY(20px);}100% {opacity: 1;-webkit-transform: translateY(0);}}
@-moz-keyframes fadeInUp {0% {opacity: 0;-moz-transform: translateY(20px);}100% {opacity: 1;-moz-transform: translateY(0);}}
@-o-keyframes fadeInUp {0% {opacity: 0;-o-transform: translateY(20px);}100% {opacity: 1;-o-transform: translateY(0);}}
@keyframes fadeInUp {0% {opacity: 0;transform: translateY(20px);}100% {opacity: 1;transform: translateY(0);}}
.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.animated {
    visibility: visible!important;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}

// @keyframes change-color1 {
//   0% {stop-color: #7dc400;}
//   100% {stop-color: #a5f910;}
// }
// @keyframes change-color1 {
//   0% {stop-color: #a5f910;}
//   100% {stop-color: #7dc400;}
// }
// #stop-1 {
//   animation: change-color1 5s ease-in-out infinite alternate;
// }
// #stop-2 {
//   animation: change-color2 5s ease-in-out infinite alternate;
// }