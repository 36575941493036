.amp-form-submit-success,
.amp-form-submit-error,
.alert{
  margin-top: 16px;
}

form .amp-form-submit-success >p.text-block {
  color: green;
}
form .amp-form-submit-error >p.text-block {
  color: red;
}

form {
    width:100%;
    max-width: 600px;
    padding:20px 0;
    
    .col {
        @include desktop {
            padding-right:10px;
        }
    }
    
    label {
        font-size:0.8em;
        text-transform: uppercase;
    }
    
    .form-field {
        
        font-family: $font-family;
        border:0;
        border-bottom: 1px solid $color-grey;
        color: $color-text;
        // font-size: 16px;
        // margin: 15px 0;
        outline: 0;
        // padding: 7px;
        // padding-bottom:10px;
        margin-bottom:30px;
        width: 100%;
        // box-sizing: border-box; 
        // -webkit-box-sizing: border-box;
        // -moz-box-sizing: border-box; 
        // background-color: #fff;
        // box-shadow: 0 0 0 1px $color-primary;

        &.user-invalid {
             box-shadow: 0 0 0 1px red;
        }
    }
    
    #btn-submit{
        float:right;
        
        i {
            font-size:2em;
            text-align: center;
            vertical-align: middle;
            transform: rotate(-21deg);
            color:#fff;
        }
        // content: 'asd';
        // font-family:'Material Icons';
    }
}




