i.picon, a.picon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  &.medium {
      font-size: 2em;
  }
  
  &.picon--20 {
    font-size: 20px;
  }
  
  text-decoration: none;
  
}

$picon-magic-wand: "\e900";
$picon-bubbles: "\e901";
$picon-users: "\e902";
$picon-google: "\ea88";
$picon-xing: "\e905";
$picon-linkedin: "\e906";
$picon-twitter: "\e907";
$picon-facebook: "\e908";
$picon-google-plus: "\e909";

.picon-magic-wand {
  &:before {
    content: $picon-magic-wand;
  }
}
.picon-bubbles {
  &:before {
    content: $picon-bubbles;
  }
}
.picon-users {
  &:before {
    content: $picon-users;
  }
}
.picon-google {
  &:before {
    content: $picon-google;
  }
}
.picon-xing {
  &:before {
    content: $picon-xing;
  }
}
.picon-linkedin {
  &:before {
    content: $picon-linkedin;
  }
}
.picon-twitter {
  &:before {
    content: $picon-twitter;
  }
}
.picon-facebook {
  &:before {
    content: $picon-facebook;
  }
}
.picon-google-plus {
  &:before {
    content: $picon-google-plus;
  }
}