.article {
    
    
    main {
        
        h2 {
            margin:4rem 0 0 0;
            font-size: 1.5rem;
        }
        h3 {
            margin-top: 2rem;
            font-weight: 600;
            font-size: 1.1rem;
            color:#000;
        }
        h4 {
            margin-bottom: 0.9rem;
        }
    
        // h2, h3, {
        //     margin-bottom: 1rem;
        //     margin-top: 1.5rem;
            
        // }
        
        ul,
        ol {
            display: block;
            -webkit-margin-before: 1em;
            -webkit-margin-after: 1em;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 40px;
            
            li {
                padding-bottom:8px;
            }
        }
        ul {
            list-style-type: disc;
        }
        
        ol {
            list-style-type: decimal;
        }
        
        p {
            margin-top: 1rem;
            padding-bottom: 1rem;
            line-height: 2rem;
            
            strong {
                font-weight:600;
                color:#000;
            }
            
        }
        a {
            font-weight: 400;
        }
        em {
            font-style: italic;
            margin-top:2rem;
            font-weight:300;
        }
     
        amp-img:not(.cover-image) {
            // @extend .shadow;
            // margin-top: 1rem;
            // margin-bottom:1rem;
            box-shadow: 1px 2px 10px rgba(0,0,0,0.15);
            
            // &.zoomable{
            //      cursor: zoom-in;
            // }
            
            &.shadow {
                // @include box-shadow;
                box-shadow: 1px 2px 10px rgba(0,0,0,0.15);
            }
            
            @include desktop {
                max-width:100%;
            }
        }
        
        pre code {
            display: block;
            padding: 1.5em;
            font-size: .875rem;
            line-height: 1.3rem;
            overflow: auto;
            max-height:20em;
        }
        
        pre {
            white-space: pre;
            hyphens: none;
            border: 1px solid #ccc;
            margin: 2em 0;
        }
        code {
            padding: .2em;
            margin: 0;
            background-color: rgba(27, 31, 35, .05);
            border-radius: 3px;
            font-size: 0.92em;
        }
        
        blockquote {
            margin: 1rem 0px;
            padding: 1rem 1rem 1rem 2rem;
            border-left: 3px solid rgb(32, 201, 151);
            background-color: rgb(241, 243, 245);
            
            p {
                margin: 0;
                padding:0;
                line-height:1.5rem;
            }
        }
        
        
        amp-lightbox {
            cursor: zoom-out;
        }
        
        .i-amphtml-fill-content {
            background: rgba(0,0,0,0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            
            
            .lightbox {
                // background: red;
                // border:2px solid blue;
                // max-width:80%;
                max-width:95%;
                height:auto;  
                
                @include desktop {
                    max-width: 80%;
                }
                img {
                  max-width:100%;
                  height:auto;
                }
            }
        }
        
        .social-media-wrapper {
            position: fixed;
            width: 100%;
            bottom: 0;
            right: 0;
            z-index: 20;
            display:flex;
            flex-direction: row;
            
            >amp-social-share {
                flex-grow:1;
                background-size:30px;
            }
            
            @include desktop {
                // position: absolute;
                width:30px;
                left:30px;
                top:50%;
                flex-direction:column;
                
                    >amp-social-share {
                    flex-grow:0;
                    border-radius: 20px;
                    margin-bottom: 4px;
                    // background-size:80%;
                }
            }
        }
    }
    form{
        max-width:100%;
    }
    #mc_embed_signup {
        margin:70px 0;
        background-color: transparent;
    }
}
    
.highlight {
    .hll { background-color: #ffffcc }
    .c { color: #60a0b0; font-style: italic } /* Comment */
    .err { border: 1px solid #FF0000 } /* Error */
    .k { color: #007020; font-weight: bold } /* Keyword */
    .o { color: #666666 } /* Operator */
    .ch { color: #60a0b0; font-style: italic } /* Comment.Hashbang */
    .cm { color: #60a0b0; font-style: italic } /* Comment.Multiline */
    .cp { color: #007020 } /* Comment.Preproc */
    .cpf { color: #60a0b0; font-style: italic } /* Comment.PreprocFile */
    .c1 { color: #60a0b0; font-style: italic } /* Comment.Single */
    .cs { color: #60a0b0; background-color: #fff0f0 } /* Comment.Special */
    .gd { color: #A00000 } /* Generic.Deleted */
    .ge { font-style: italic } /* Generic.Emph */
    .gr { color: #FF0000 } /* Generic.Error */
    .gh { color: #000080; font-weight: bold } /* Generic.Heading */
    .gi { color: #00A000 } /* Generic.Inserted */
    .go { color: #888888 } /* Generic.Output */
    .gp { color: #c65d09; font-weight: bold } /* Generic.Prompt */
    .gs { font-weight: bold } /* Generic.Strong */
    .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
    .gt { color: #0044DD } /* Generic.Traceback */
    .kc { color: #007020; font-weight: bold } /* Keyword.Constant */
    .kd { color: #007020; font-weight: bold } /* Keyword.Declaration */
    .kn { color: #007020; font-weight: bold } /* Keyword.Namespace */
    .kp { color: #007020 } /* Keyword.Pseudo */
    .kr { color: #007020; font-weight: bold } /* Keyword.Reserved */
    .kt { color: #902000 } /* Keyword.Type */
    .m { color: #40a070 } /* Literal.Number */
    .s { color: #4070a0 } /* Literal.String */
    .na { color: #4070a0 } /* Name.Attribute */
    .nb { color: #007020 } /* Name.Builtin */
    .nc { color: #0e84b5; font-weight: bold } /* Name.Class */
    .no { color: #60add5 } /* Name.Constant */
    .nd { color: #555555; font-weight: bold } /* Name.Decorator */
    .ni { color: #d55537; font-weight: bold } /* Name.Entity */
    .ne { color: #007020 } /* Name.Exception */
    .nf { color: #06287e } /* Name.Function */
    .nl { color: #002070; font-weight: bold } /* Name.Label */
    .nn { color: #0e84b5; font-weight: bold } /* Name.Namespace */
    .nt { color: #062873; font-weight: bold } /* Name.Tag */
    .nv { color: #bb60d5 } /* Name.Variable */
    .ow { color: #007020; font-weight: bold } /* Operator.Word */
    .w { color: #bbbbbb } /* Text.Whitespace */
    .mb { color: #40a070 } /* Literal.Number.Bin */
    .mf { color: #40a070 } /* Literal.Number.Float */
    .mh { color: #40a070 } /* Literal.Number.Hex */
    .mi { color: #40a070 } /* Literal.Number.Integer */
    .mo { color: #40a070 } /* Literal.Number.Oct */
    .sa { color: #4070a0 } /* Literal.String.Affix */
    .sb { color: #4070a0 } /* Literal.String.Backtick */
    .sc { color: #4070a0 } /* Literal.String.Char */
    .dl { color: #4070a0 } /* Literal.String.Delimiter */
    .sd { color: #4070a0; font-style: italic } /* Literal.String.Doc */
    .s2 { color: #4070a0 } /* Literal.String.Double */
    .se { color: #4070a0; font-weight: bold } /* Literal.String.Escape */
    .sh { color: #4070a0 } /* Literal.String.Heredoc */
    .si { color: #70a0d0; font-style: italic } /* Literal.String.Interpol */
    .sx { color: #c65d09 } /* Literal.String.Other */
    .sr { color: #235388 } /* Literal.String.Regex */
    .s1 { color: #4070a0 } /* Literal.String.Single */
    .ss { color: #517918 } /* Literal.String.Symbol */
    .bp { color: #007020 } /* Name.Builtin.Pseudo */
    .fm { color: #06287e } /* Name.Function.Magic */
    .vc { color: #bb60d5 } /* Name.Variable.Class */
    .vg { color: #bb60d5 } /* Name.Variable.Global */
    .vi { color: #bb60d5 } /* Name.Variable.Instance */
    .vm { color: #bb60d5 } /* Name.Variable.Magic */
    .il { color: #40a070 } /* Literal.Number.Integer.Long */
    
}