
// General Dimensions
$container-size: 1000px;
$max-size: 1000px;
$max-body-size: 2100px;

// Breakpoints
$mobile-breakpoint: 768px;
$desktop-breakpoint: 1024px;

// Grid
$grid-columns: 12;
$grid-max-width: 99%;

// Header Dimensions
$header-height: 60px;
$header-height-desktop: 70px;
$header-nav-width: 1200px;

// colors new
$primary-color: #0594CB;
$primary-color-dark: #343a40;
$primary-color-light: #EBEFF0;
$accent-color: #EBB951;
$accent-color-light: #FF4088;
$accent-color-dark: #33ba91;
$text-color-primary: #373737;
$text-on-primary-color: #fff;
$text-color-secondary: #ccc;
$text-color-disabled:  #F7f7f7;
$divider-color:        #f6f6f6;
$warn-color: red;
$blue: #0594CB;
$grey-icon: #747474;


// Colors
$header-background-color: #fff;
$color-orange: #ffc107;
$color-blue-xlight: #E3EBF5;
$color-blue-light: #44bbf4;
$color-blue-mid: #447CBC;
$color-blue-dark: #3288E6;

$color-primary: $color-blue-mid; // blue
$color-icons: #EB6049;
$color-icons: #e8d003;

$color-grey-primary: #333333;
$color-grey-secondary: #7b7979;
$color-grey-light: #efefef;
$color-grey-xlight: #fafdff;
$color-grey-section: #f5f5f5;

// Texts
$color-text: #414141;
$color-text-alt: $color-text;
$desktop-font-size: 16px;
$desktop-line-height: 24px;
$mobile-font-size: 15px;
$mobile-line-height: 1.625;

// Links
$color-link: $color-primary;
$color-link-hover :$color-blue-light;

$font-family: 'Roboto',arial,sans-serif;
$font-family: 'Oxygen',arial,sans-serif;
$font-family: 'Source Sans Pro', sans-serif;
$font-family: 'Open Sans', sans-serif;

$h1-font-family: $font-family;
$h2-font-family: $font-family;
$h3-font-family: $font-family;


$color-header-background: #fff;
$color-header-link: $color-grey-secondary;
$color-header-link-hover: $color-text;


// Drawer
$drawer-color: $color-grey-section;

// Tabs
$color-tab: $color-orange;

// Footer
$color-footer-background: $color-grey-xlight;


// Images 
$images-path: "/assets/images/";

//////////////////////////////
// Colors









$color-grey-50: #fafafa;
$color-grey-100: #f5f5f5;
$color-grey-200: #e9e9e9;
$color-grey-300: #d5d5d5;
$color-grey-500: #9E9E9E;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;


$color-charcoal: #293b4c;
$color-charcoal-light: rgba(113, 129, 136, 1);
$color-charcoal-xlight: rgba(236,239,241 ,1);
$color-charcoal-xxlight: rgba(236,239,241 ,0.5);



// $color-blue: #4b91ef;
$color-green: #7DC400;
$color-ocre: #CCA876;
$color-bordeaux: #ca1717;
$color-purple: #061283;
$color-pink: #fd3c3c;
$color-teal: #138d90;
$color-mustard: #ffb74c;


// $color-primary: $color-green;
$color-primary-dark: #00796B; 

$color-secondary: #4b91ef;



$color-sidebar-header: $color-header-background;
$color-sidebar-background: $color-header-background;
$color-sidebar-link: $color-header-link;
$color-sidebar-border-active: $color-green;

// $color-text: #5c5c5c;
$color-text-titles: $color-grey-primary;
$color-titles-underline: $color-charcoal-light;

// $color-footer-background: $color-grey-primary;

$color-separator: $color-grey-200;
$color-separator-accent: $color-primary;


$color-link-action: $color-primary;



$color-blockquote-background: rgba(232,245,233 ,0.5);
$color-blockquote-border: rgba(38,166,154 ,1);


$sidebar-link-height: 45px;



// OLD

$color-green-ripple: rgba(71, 149, 88, .25);


$color-green-alt: #2c655a;
$color-green-light: #9be34e;
$color-cyan: #00bcd4;
$color-cyan-ripple: rgba(80, 183, 206, .25);
$color-grey: #dddddd;



$color-lightgrey-transparent: rgba(100, 0, 100, .025);
$color-darkgrey: #797c82;
$color-teal: #03bcd4;
$color-white: #fff;
$color-lightblue: #3ab3eb;
$color-verydarkblue: #1b1464;
$color-brightblue: #29aae3;
$color-midblue: #0379C4;

$color-yellow: #f8c144;
$color-orange: #f68c1e;
$color-yellow-ripple: rgba(246, 191, 68, .25);

$color-hover-bg: $color-grey-100;
$color-bg: $color-white;

// TODO: remove these ?
$color-medgrey: #777777;
$color-black-95: #333333;
$color-black-87: #212121;

// TODO: Legacy, remove these vars
$color-lightgrey: $color-grey-100;


$margin: 20px;



$mobile-breakpoint: 760px;
// $mobile-breakpoint: 1500px;
$mobile-medium-breakpoint: 750px;
$mobile-baby-breakpoint: 320px;
$tablet-breakpoint: 1024px;
$tablet-portrait-breakpoint: 768px;

$transition-snap: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
$transition-snap-short: all 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

// $html-padding: 15px;
$html-padding: 0px;
$header-padding: 25px;
// $header-height: 72px;
$mobile-header-height: 45px;
