.theme--block, .box {
    @extend %clearfix;
    background-color: #fff;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 0;
    align-content: flex-start;
    display:flex;
    padding-bottom: 35px;
    border: 0;
    align-content: flex-start;
    
    
    &.transparent {
        background:transparent;
    }
    
    &.inline {
        flex-direction:row;
        
        i {
            margin-right: 20px;
            width:30px;
            height: 30px;
        }
    }
    
    
    h1,h2,h3,h4,h5,p,ul {
        color: $color-text;
        font-size: 14px;
    }
    h3 {
        padding: 0;
        font-weight: 600;
        text-transform: uppercase;
        // color: $color-text;
        margin: 0px 0 3px 0;
    }
    .material-icons {
        font-size: 24px;
        margin-bottom: 16px;
        color: $color-primary;
        // color: $color-icons;
        width:1em;

    }
    
    .theme--image {
        width:100%;
        min-height: 160px;
        margin-bottom: 20px;
    }
}

.box{
    padding: 20px 20px 20px 15px;
    border-radius: 3px;
    margin-bottom:20px;
    display: block;
    
    &.shadow {
        @include box-shadow;
    }
}

// .card {
    
//     position:relative;
//     width:100%;
//     background-color:#fff;
    
//     &.shadow {
//         @include box-shadow;
//     }
    
//     .card--image {
//         display:block;
//         width:100%;
//         min-height: 220px;
//     }
    
//     .card--title, .card--content, .card--action {
//         padding: 10px 15px;
//         font-size:15px;
//     }
    
//     .card--title, h2 {
//         padding-top: 20px;
//         padding-bottom: 0px;
//         margin-bottom:1em;
//         font-weight:600;
//         line-height:1.2em;
//         font-size: 1.3rem;
        
//         a {
//             color:$color-text;
            
//             &:hover {
//                 text-decoration: underline;
//             }    
//         }
        
//     }
    
//     .card--content {
//         margin-bottom:40px;
//     }
    
//     .card--action {
//         position: absolute;
//         bottom:0;
//         text-align:right;
//     }
    
    
//     &.article-card {
        
//         .card--title, h2, .card--content, .card--action {
//             padding-left:0;
//             padding-right:0;
//             padding-top:0;
//             // font-size:15px;
//         }
        
//         .card--category {
//             font-weight:600;
//             padding-bottom:10px;
            
//             &:after{
//                   content: " \005F";
//                   font-size: 1.3em;
//                   font-weight: 600;
//                 // border-bottom: 1px solid black;

//             } 
//         }
//     }
    
// }