
@mixin mobile {
  @media (max-width: #{$mobile-breakpoint - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-breakpoint}) and (max-width: #{$desktop-breakpoint - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-breakpoint}) {
    @content;
  }
}

@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
}

@mixin svg-icon($url) {
  background-image:url($url);
}

@mixin overlay {
  content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

@mixin background-overlay($color, $opacity:0.95) {
  
  &:before {
		    content: " ";
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    opacity:$opacity;
		    @if $color == "blue" {
          background: linear-gradient(120deg,$color-blue-dark,$color-blue-light);
        } @else if $color == "dark" {
          background: #000;
        }
		}
}

@mixin background-image($url) {
  background-image: url('#{$images-path}#{$url}');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}

@mixin box-shadow {
  box-shadow: 0px 7px 50px 0px rgba(38,50,56,0.25);
}

@mixin transparent {
  background: transparent;
}


@mixin default-transition {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


%clearfix {
  &:before, 
  &:after {
      content: " ";
      display: table;
  }
  &:after {
      clear: both;
  }
}