.home {
    
    // $bg: "https://images.unsplash.com/photo-1494707924465-e1426acb48cb?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=d7cac606b3752d340f2c342f32536727&auto=format&fit=crop&w=1350&q=80";
    $bg: "/assets/images/hero-2-min.jpeg";
    
    .hero {
        // background: linear-gradient(-120deg, rgba(20, 30, 48, 0.9), rgba(36, 59, 85, 0.8)), url(/assets/images/bg-desktop-min.jpg);
        background: linear-gradient(-120deg, rgba(20, 30, 48, 0.9), rgba(36, 59, 85, 0.8)), url($bg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        
        .jumbotron {
            #btn_jumbotron_cta{
                margin-bottom:2rem;
                width: 250px;
            
                @include desktop {
                    margin-top:2rem;
                }
            }
        }
    }
    
    .solutions-row {
        background: rgba(20, 30, 48, 0.8);
        background: rgba(255, 255, 255, 1);
        
    }
    
    
    .products {
        i {margin-bottom:30px;}
    }
}

