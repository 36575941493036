.svg-icon {
  width: 4rem;
  height: 4rem;
  stroke-width: 0;
  stroke: currentColor;
  // margin: 1rem;
  
  &.tick {
    width:2rem;
  }
  
  &.auto {
    width: auto;
    height: auto;
  }
  
  
  
  // &.circled {
  //   background: #ffc0c0;
  //   margin:1rem;
  //   padding:2rem;
  //   width:6rem;
  //   height:6rem;
  //   border-radius: 30%;
  //   // width: 100px;
  //   // height: 100px;
  //   // border-radius: 50%;
  //   // text-align: center;
  //   // line-height: 100px;
  //   // vertical-align: middle;
  //   // padding: 30px;
  // }
   
  
  // path .colored {
  //     fill: #447CBC;
  // }
}

#logo--chrome {
  width:auto;
  height:auto;
}