.why-loyall {

    
    >amp-img.how-image {
        right: 150px;
        top:-50px;
    }
    
    
    .box.badge {
    	max-width:200px;
    }
    
    .values {
    	@include box-shadow;
    	text-align: center;
    }
	
	.segment {
		.theme--image {
			&.business {
				@include background-image('segment-company-min.jpg');
			}
			&.edu {
				@include background-image('segment-education-min.jpg');
			}
			&.ngo {
				@include background-image('segment-ngo-min.jpg');
			}
		}
	}
    
}

$timeline-color: $color-primary;

@mixin prefix($prop, $val) {
	@each $prefix in '-webkit-', '-moz-', '-ms-', '' {
		#{$prefix}#{$prop}: $val;
	}
}


.timeline {
	width: 100%;
	position: relative;

	&:before {
		content:"";
		width: 1px;
		height: 100%;
		background: $timeline-color;
        background-image:linear-gradient(to bottom, $timeline-color 90%, #fff);
		left: 50%;
		top: 0;
		position: absolute;
	}

	&:after {
		content: "";
		clear: both;
		display: table;
		width: 100%;
	}
	
	.timeline-item {
		
		margin-bottom: 50px;
		position: relative;
		@extend %clearfix;
		
		@include desktop {
			&:not(:first-child) {
			    margin-top:-80px;
			}
		}
	
		.timeline-icon {
			background: #F5F5F5;
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0;
			left: 50%;
			overflow: hidden;
			margin-left: -25px;
			@include prefix(border-radius, 50%);
			border:1px solid #0da1e3;

			svg, .material-icons {
				position: relative;
				top: 10px;
				left: 10px;
				color:#0da1e3;
				font-size: 30px;
			}
			
			transition:
			    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1),
			    background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
		}

		.timeline-content {
			width: 45%;
			transition:
			    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1),
			    background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);

			&:before {
				content: '';
				position: absolute;
				left: 45%;
				top: 20px;
				width: 0; 
				height: 0; 
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent; 
				border-left:7px solid $timeline-color; 
			}
			
			&:hover {
     
     			@include box-shadow;
				.timeline-icon {
					background: $timeline-color;
				}
				
				.material-icons {
					color:#fff;	
				}
			}

			&.right {
				float: right;

				&:before {
					content: '';
					right: 45%;
					left: inherit;
					border-left: 0;
					border-right: 7px solid $timeline-color;
				}
			}
		}
	}
}


@include mobile {
    .timeline {
		&:before {
			left:10px;
			top:5px;
		}
		
		.timeline-item {
			.timeline-content {
				width: 90%;
				float: right;
				
				&:before, &.right:before {
					left: 10%;
					margin-left: -6px;
					border-left: 0;
					border-right: 7px solid $timeline-color;
				}
			}

			.timeline-icon {
				width:40px;
				height:40px;
				left: 15px;
				top:5px;
				
				.material-icons {
					font-size: 20px;
				}
			}
		}
	}
}